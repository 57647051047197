<template>
<transition name="fadePage" mode="out-in">
<div class="PurchasingPledgeNode">
  <Top-Nav></Top-Nav>
  <div class="con_bac">
    <div>{{$t('menu.currentNodeRanking')}}<span class="fwbold font_Bebas">{{currentNodeRanking}}</span></div>
    <div class="mt8">{{$t('menu.myComputingPower')}}<span class="fwbold font_Bebas">{{formatAmountMethod(myComputingPower)}}</span></div>
  </div>
  <div class="space_between numPledge">
    <div class="name">{{$t('menu.pledgeQuantity')}}</div>
    <div class="icon_d flex_start align_center">
      <img src="../../../../../public/images/img_ZEED.png" alt="">
      <span>USDT</span>
    </div>
  </div>
  <div class="balance_box">
    <div class="num">2000</div>
    <div class="num_des">{{$t('menu.balance')}}{{usdtBalance}}</div>
  </div>
  <div class="tip_info">{{$t('menu.tip')}}{{$t('menu.tipRedeemed')}}</div>
  <div class="btn_group">
    <button class="blue_bac_btn w100" :disabled="pledgeBtn"  @click="pledge" :class="{disabled_btn:pledgeBtn}" v-if="isHidePledge">{{$t('menu.pledge')}}</button>
    <!-- <button class="no_bac_btn mt16 redem_btn" :disabled="redemptionBtn" @click="redemptionUsdtAssets" :class="{disabled_btn:redemptionBtn}">{{$t('menu.redemptionUsdtAssets')}}</button> -->
    <button class="no_bac_btn mt16 redem_btn" :disabled="redemptionBtn" @click="redemptionUsdtAssets" :class="{disabled_btn:redemptionBtn}" v-if="!isHidePledge">{{$t('menu.redemptionUsdtAssets')}}</button>
    
    <router-link :to="{name:'PurchasingNodeRanking',path:'/purchasingNodeRanking'}" class="opacity_bac_btn ranking_btn">
      <span>{{$t('menu.jdphb')}}</span> 
      <img src="../../../../../public/images/right.png" alt="">
    </router-link>
  </div>
  
  
</div>
</transition>
</template>
<script>
import '../styles/common.css';
import '../../../../style/pledgeNode.css';
import TopNav from '../common/PurchasingTop.vue';

const poolTwo = require('../../../../utils/poolTwo')

export default {
  name: 'PurchasingPledgeNode',
  components:{TopNav},
  data() {
    return {
      formatAmountMethod:poolTwo.amountConversion,
      usdtBalance:0, // USDT余额
      currentNodeRanking:0,//当前节点排名
      myComputingPower:0,//我的算力
      // isDisabl_a:false,//禁用a标签
      isHidePledge:true, // 是否隐藏质押
      pledgeBtn:false,  //质押按钮是否禁用
      redemptionBtn:false,  //赎回按钮是否禁用
    }
  },
  created:function(){
    let that = this
    let myAddress = localStorage.getItem('address')
    if(!myAddress || typeof myAddress == "undefined" || myAddress == 0){
      console.log('没有钱包地址不获取数据',myAddress)
      return 
    }

    // 获取USDT余额
    poolTwo.getUsdtBalance().then(res => {
      that.usdtBalance = res
    })

    /** 获取（当前节点排名） */ 
    // // 节点数据（取得到就显示实际的名次，取不到（1、如果是节点，显示排名>21,2、非节点显示0））
    poolTwo.userNodeInfo().then(res=>{
      let _isNode = res._isNode // 是否是节点
      that.isHidePledge = !_isNode // 判断是否展示质押或赎回
      // 节点排行榜
      poolTwo.nodeRanking().then(res=>{
        // 判断当前地址是否存在排名
        let _accounts = res._accounts
        _accounts.forEach((value,index)=>{
          let account = value.toLowerCase()
          if(account == myAddress){
            that.currentNodeRanking = index+1 // 当前节点排名
          }
        })
        
        if(that.currentNodeRanking == 0 && _isNode){
          that.currentNodeRanking = '> 21'
        }
      })
    })
    

    /** 获取（我的算力） */
     // 用户挖矿数据
    poolTwo.userMintInfo().then(res=>{
      that.myComputingPower = res._pow // 用户算力
    })

    

  },
  methods:{
    // 质押
    pledge:function(){
      let that = this
      // 判断质押余额是否够
      if(that.usdtBalance<2000){
        return this.$toast('USDT'+this.$t('menu.creditLow'));
      }
      that.pledgeBtn = true
      poolTwo.stakeNode().then(res=>{
        console.log('节点质押结果',res);
        that.pledgeBtn = false;
        this.$toast(this.$t('menu.extractionSucceeded'));
        if(typeof res !=='undefined'){
          that.isHidePledge = false
        }
      })
    },
    // 赎回USDT质押资产
    redemptionUsdtAssets:function(){
      let that = this
      that.redemptionBtn = true
      poolTwo.removeNode().then(res=>{
        console.log('节点赎回结果',res)
        that.redemptionBtn = false;
        this.$toast(this.$t('menu.extractionSucceeded'));
        if(typeof res !=='undefined'){
          that.isHidePledge = true
        }
      })
    }
  }
}
</script>
<style scoped>
.redem_btn{
  width: 100%;
  background: none;
}
.w100{
  width: 100%;
}
</style>